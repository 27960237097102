function createSliders() {
    new Swiper('.main-slider', {
        loop: true,
        autoplay: {
            delay: 6000,
            disableOnInteraction: false
        },
        pagination: {
            el: '.main-slider .slider__dots',
            clickable: true,
            bulletClass: 'slider__dot',
            bulletActiveClass: 'slider__dot_active'
        },
        navigation: {
            prevEl: '.main-slider .slider__arrow_prev',
            nextEl: '.main-slider .slider__arrow_next'
        },
        breakpoints: {
            960: {
                allowTouchMove: false
            }
        }
    });

    new Swiper('.catalog__slider .swiper-container', {
        spaceBetween: 16,
        slidesPerView: 'auto',
        freeMode: true,
        navigation: {
            prevEl: '.catalog__slider .slider__arrow_prev',
            nextEl: '.catalog__slider .slider__arrow_next',
            disabledClass: 'slider__arrow_disabled'
        },
        breakpoints: {
            960: {
                slidesPerView: 3,
                allowTouchMove: false,
                freeMode: false
            }
        }
    });

    new Swiper('.brands__slider .swiper-container', {
        spaceBetween: 16,
        slidesPerView: 'auto',
        freeMode: true,
        navigation: {
            prevEl: '.brands__slider .slider__arrow_prev',
            nextEl: '.brands__slider .slider__arrow_next',
            disabledClass: 'slider__arrow_disabled'
        },
        breakpoints: {
            960: {
                slidesPerView: 3,
                allowTouchMove: false,
                freeMode: false
            }
        }
    });
}

createSliders();
